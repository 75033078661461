import Immutable from 'immutable';
import { getBugsnag } from '../../../../src/app/services';

const bugsnag = getBugsnag();

/**
 * Convert state from ImmutableJS to plain JS objects
 * @param state
 */
const stateTransformer = state => {
  const newState = {};

  Object.keys(state).forEach(i => {
    if (Immutable.Iterable.isIterable(state[i])) {
      newState[i] = state[i].toJS();
    } else {
      newState[i] = state[i];
    }
  });

  return newState;
};

export default function createMiddleware() {
  return store => next => action => {
    try {
      return next(action);
    } catch (err) {
      console.error('[bugsnag-middleware] Reporting error to Bugsnag', action.type, err); // eslint-disable-line

      if (process.env.BABEL_ENV !== 'mocha') {
        bugsnag.notify({
          action: {
            type: action.type,
            payload: action.payload,
          },
          err,
          state: stateTransformer(store.getState()),
        });
      }

      throw err;
    }
  };
}
