import Pusher from 'pusher-js';
import { Map } from 'immutable';
import { isOnline, isElectron } from 'selectors/configSelectors';
import { loadFromCookie } from '../businessLogic/cookieStorage';

export default class PusherManager {
  constructor(debug = false) {
    if (debug) PusherManager.enableDebug();
    this.client = null;
    this.subscriptions = Map();

    return this;
  }

  setStore(store) {
    this.store = store;
    return this;
  }

  setAPIFactory(APIFactory) {
    this.APIFactory = APIFactory;
    return this;
  }

  addSubscription(channel, subscription) {
    this.subscriptions = this.subscriptions.set(channel, subscription);
  }

  getSubscription(channel) {
    return this.subscriptions.get(channel);
  }

  // todo remove when conversation ended

  /**
   * @returns {Promise}
   */
  connect() {
    // this should never run, since the app is disconnecting as needed, but this is here just in case
    if (this.client) this.client.disconnect();

    return new Promise((resolve, reject) => {
      this.client = new Pusher(this.getPusherKey(), {
        encrypted: true,
        authEndpoint: `${this.getApiUrl()}/pusher/auth/agent`,
        auth: {
          headers: {
            'X-Auth-Token': this.getAuthToken(),
            Accept: 'application/json',
            'X-API-Version': 1,
          },
        },
      });
      this.client.connection.bind('state_change', this.logConnectionStateChanges.bind(this));

      this.client.connection.bind('connected', resolve);
    });
  }

  disconnect() {
    if (!this.client) return;
    this.client.disconnect();
  }

  logConnectionStateChanges({ previous, current }) {
    if (isOnline(this.store)) {
      const api = this.APIFactory.getInstance(this.store);
      api
        .post('/pusher/log-connection-state', {
          app: isElectron(this.store) ? 'agent-electron' : 'agent-web',
          previous,
          current,
        })
        .catch(() => ({
          // ignore errors logging
        }));
    }
  }

  /**
   * @private
   */
  static enableDebug() {
    Pusher.log = message => {
      if (window.console && window.console.log) {
        window.console.info(message);
      }
    };
  }

  getPusherKey() {
    return this.store.config.getIn(['pusher', 'key']);
  }

  getApiUrl() {
    return this.store.config.getIn(['api', 'baseUrl']);
  }

  getAuthToken() {
    const thisCookie = loadFromCookie();
    return thisCookie.token;
  }
}
