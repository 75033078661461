/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { createBrowserHistory } from 'history';

let history: RouteComponentProps['history'] | History = window.history;

// The tests depend on an old instance of history.
if (process.env.BABEL_ENV === 'mocha') {
  history = createBrowserHistory();
}

// This must be used once and rendered once, asap.
export const ExtractHistoryHack = withRouter(props => {
  history = props.history;
  return <React.Fragment />;
});

export { history };
