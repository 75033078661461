import bugsnagJS from '@bugsnag/browser';
import { Client, IConfig } from '@bugsnag/core';
import bugsnagReact from '@bugsnag/plugin-react';
import React from 'react';

const notifyReleaseStages = ['dev', 'test', 'demo', 'prod'];

// https://docs.bugsnag.com/platforms/javascript/configuration-options/
const baseConfig: Partial<IConfig> = {
  appType: 'client',
};

export const getBugsnagConfig = (): IConfig => {
  // todo(tech-debt): remove this block when moved to s3 this is only here for the php blade template.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((window as any).CONFIG && (window as any).CONFIG.config) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const config = (window as any).CONFIG.config as any;
    return {
      ...baseConfig,
      apiKey:config.bugsnag.key,
      notifyReleaseStages,
      releaseStage: config.releaseStage,
      releaseVersion: config.releaseVersion,
    };
  }

  // todo(tech-debt): consolidate all process.env in one file/module w/o react context.
  if (
    process.env.BABEL_ENV === 'jest' ||
    process.env.BABEL_ENV === 'mocha'
  ) {
    baseConfig.logger = null;
  }
  const apiKey = process.env.BUGSNAG_JAVASCRIPT_API_KEY;
  const releaseStage = process.env.APP_ENV;
  const releaseVersion = process.env.RELEASE_VERSION;

  if (apiKey) {
    return {
      ...baseConfig,
      apiKey,
      notifyReleaseStages,
      releaseStage,
      releaseVersion,
    };
  }

  throw Error('You configured BugSnag incorrectly.');
};

// Do not import this const, it is exported for use in tests only.
// Use one of the options below.
// - todo(): React Provider / Hooks
// - todo(): Overmind effects
export const bugsnag: {
  instance: Client | null;
} = {
  instance: null,
};

const initBugsnag = (): Client => {
  const client = bugsnagJS(getBugsnagConfig());

  client.use(bugsnagReact, React);

  bugsnag.instance = client;

  return bugsnag.instance;
};

export const getBugsnag = (): Client => (
  bugsnag.instance === null ? initBugsnag() : bugsnag.instance
);
