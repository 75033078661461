import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Main } from '../../app';
import { getBugsnag } from '../../app/services';
import FallbackComponent from '../../../public/components/Shared/ErrorBoundary/Fallback';
import configureStore from '../../../public/store/configureStore';

const ErrorBoundary = getBugsnag().getPlugin('react');
const store = (configureStore as unknown as Function)();

export const App = (
  <ErrorBoundary FallbackComponent={FallbackComponent}>
    <Provider store={store}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>
);
