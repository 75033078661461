import { Map, List, fromJS } from "immutable";
import { mapFeedFields, getAppEnv } from "businessLogic/util";
import * as types from "../constants/actionTypes";
import { Cookies } from "react-cookie";

const initialState = Map({
  pusher: Map({}),
  api: Map({}),
  google: Map({}),
  browser: Map({
    isChromeBrowser: false,
    isSupportedBrowser: false,
    isFocused: true,
    webRtcSupport: false,
    chromeExtension: Map({
      installed: false,
      version: undefined,
      minVersion: 2,
      id: "cjdkbdckofhgekglchbeaacbodmfdglg",
    }),
  }),
  connection: Map({ isOnline: false }),
  feedFields: Map({}),
  callCenterTeams: List(),
  conversationsCountries: List(["US", "CA", "GU", "MX", "UM"]),
});

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_CONFIG_FROM_PAGE:
      return setConfigFromPage(state, payload);
    case types.FORCE_REFRESH:
      return state.set("refreshRequired", true);
    case types.GREAT_SMUSH_FORCE_REFRESH:
      return greatSmushForceRefresh(state, payload);
    case types.BROWSER_CONFIG_FETCHED:
      return browserConfigFetched(state, payload);
    case types.WINDOW_FOCUS_CHANGE:
      return state.setIn(["browser", "isFocused"], payload.isFocused);
    case types.CONNECTION_STATUS_CHANGE:
      return state.setIn(["connection", "isOnline"], payload.isOnline);
    case types.FEED_FIELDS_FETCHED:
      return feedFieldsFetched(state, payload);
    case types.CALL_CENTER_TEAMS_FETCHED:
      return state.set("callCenterTeams", fromJS(payload.teams));
    default:
      return state;
  }
}

function setConfigFromPage(state, config) {
  return state.withMutations((state) => {
    state.setIn(["pusher", "key"], config.pusher.key);
    state.setIn(["api", "baseUrl"], config.api.baseUrl);
    state.setIn(["google", "key"], config.google.key);
    state.setIn(["bugsnag", "key"], config.bugsnag.key);
    state.setIn(["releaseStage"], config.releaseStage);
    state.setIn(["releaseVersion"], config.releaseVersion);
    state.setIn(["split", "authorizationKey"], config.split?.authorizationKey);
  });
}

function browserConfigFetched(
  state,
  {
    isChromeBrowser,
    isSupportedBrowser,
    isElectron,
    webRtcSupport,
    extensionInstalled,
    extensionVersion,
  }
) {
  state = state.setIn(["browser", "isChromeBrowser"], !!isChromeBrowser);
  state = state.setIn(["browser", "isSupportedBrowser"], !!isSupportedBrowser);
  state = state.setIn(["browser", "isElectron"], !!isElectron);
  state = state.setIn(["browser", "webRtcSupport"], webRtcSupport);
  state = state.mergeIn(["browser", "chromeExtension"], {
    installed: !!extensionInstalled,
    version: extensionVersion,
  });
  return state;
}

function greatSmushForceRefresh(state, payload) {
    const env = getAppEnv()
    const cookieName = `${env}_conv_id`;
    const oldUserId = `${cookieName}=${payload?.oldUserId}`;
    const newUserId = payload?.newUserId;
    const cookies = new Cookies();

    if (!document.cookie.match(oldUserId)) {
        return state;
    }

    cookies.set(cookieName, newUserId, {
        domain: '.conversations.dealerinspire.com'
    });

  return state.set('refreshRequired', true);
}

function feedFieldsFetched(state, { fields }) {
  return state.set("feedFields", mapFeedFields(fields));
}
