export const messageSendErrors = {
  NO_MATCHING_CONVO: 'NO_MATCHING_CONVO',
  ALREADY_ENDED: 'ALREADY_ENDED',
  SMS_NOT_CONFIRMED: 'SMS_NOT_CONFIRMED',
  SMS_REVOKED: 'SMS_REVOKED',
  AUTH_ERROR: 'AUTH_ERROR',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  BAD_REQUEST: 'BAD_REQUEST',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  TIMEOUT: 'TIMEOUT',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
};

export default {};
